import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import Breadcrumbs from '../../Components/Breadcrumb';
import SEO from '../../Components/SEO';
import GrammarMenu from '../../Components/GrammarMenu';
import sentencesData from './Data/secondconditional.json'; 
import Sidebar from '../../Components/Sidebar';


const SecondConditional = () => {
  const [answers, setAnswers] = useState(Array(10).fill(''));
  const [evaluation, setEvaluation] = useState(Array(10).fill(''));
  const [displayedSentences, setDisplayedSentences] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isGridVisible, setIsGridVisible] = useState(false);

  const [grammarData] = useState([
    ["I am", "You are", "He/She/It is", "We/You/They are"],
    ["I am not", "You are not", "He/She/It is not", "We/You/They are not"],
    ["Am I...?", "Are you...?", "Is he/she/it...?", "Are we/you/they...?"],
  ]);

  const uniqueTopics = useMemo(() => {
    const topics = sentencesData.map((sentence) => sentence.topic);
    return Array.from(new Set(topics)).map((topic) => ({ label: topic, value: topic }));
  }, []);

  const generateNewSentences = useCallback(() => {
    const filteredSentences = selectedTopic
      ? sentencesData.filter((sentence) => sentence.topic === selectedTopic)
      : sentencesData;

    const newSentences = filteredSentences.slice(currentIndex, currentIndex + 10);

    setDisplayedSentences(newSentences);
    const resetLength = newSentences.length || 10;
    setAnswers(Array(resetLength).fill(''));
    setEvaluation(Array(resetLength).fill(''));
  }, [currentIndex, selectedTopic]);

  useEffect(() => {
    generateNewSentences();
  }, [currentIndex, selectedTopic, generateNewSentences]);

  const handleChange = (index, value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEvaluation = displayedSentences.map((sentence, index) => {
      const correctAnswer = sentence.answer;
      const userAnswer = answers[index]?.trim();

      if (!userAnswer) return '';

      return userAnswer.toLowerCase() === correctAnswer?.trim().toLowerCase()
        ? 'correct'
        : 'incorrect';
    });
    setEvaluation(updatedEvaluation);
  };

  const handleReset = () => {
    setAnswers(Array(displayedSentences.length).fill(''));
    setEvaluation(Array(displayedSentences.length).fill(''));
  };

  const handleNextExercise = () => {
    const filteredSentences = selectedTopic
      ? sentencesData.filter((sentence) => sentence.topic === selectedTopic)
      : sentencesData;

    const nextIndex = (currentIndex + 10) % filteredSentences.length;
    setCurrentIndex(nextIndex);
  };

  const handlePreviousExercise = () => {
    const filteredSentences = selectedTopic
      ? sentencesData.filter((sentence) => sentence.topic === selectedTopic)
      : sentencesData;

    const previousIndex = (currentIndex - 10 + filteredSentences.length) % filteredSentences.length;
    setCurrentIndex(previousIndex);
  };

  const CustomInput = (props) => {
    return <components.Input {...props} readOnly />;
  };

  const toggleGridVisibility = () => {
    setIsGridVisible((prev) => !prev);
  };

  return (
    <div>
      <SEO
        title="Grammar Exercises - Second Conditional"
        description="A big list of English grammar exercises to practice for exams"
        name="English Exam Exercises"
        type="article"
      />
      <div className="grammar-container">
        <div className="introtext">
          <Breadcrumbs />
          <div className="grammartitle">
          <span className="label label-b2">B2</span> 
          <h3 className="mt-2">Second Conditional</h3>
          
          </div>
          <div className="descriptionbox">
          <p>
            Language point explanation
          </p>

          <div className="grammarctrl descriptionbox">
            <button type="button" className="grammarbtn" onClick={toggleGridVisibility}>
              {isGridVisible ? 'Hide Grammar' : 'Show Grammar'}
            </button>
          </div>

          {isGridVisible && (
            <div className="grammar-display-grid">
              {grammarData.map((row, index) => (
                <div key={index} className="grammar-display-row">
                  {row.map((form, i) => (
                    <div key={i} className="grammar-display-item">
                      {form}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
          <div className="exercises-container">

            <div className="exercisectrl">
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}><i className="fas fa-arrow-left"></i> Prev Set 
              </button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}>
              Next Set <i className="fas fa-arrow-right"></i>
              </button>
              <button type="submit" className="checkbtn" onClick={handleSubmit}>Check</button>
              <button type="submit" className="checkbtn" onClick={handleReset}>Reset</button>
              <div className="filter-container">
              <Select
                value={selectedTopic ? { label: selectedTopic, value: selectedTopic } : null}
                onChange={(option) => setSelectedTopic(option?.value || null)}
                options={[{ label: 'All Topics', value: null }, ...uniqueTopics]}
                placeholder="Sentence topic"
                isClearable
                components={{ Input: CustomInput }}
              />
            </div>
            </div>
            <p className="descriptionbox">Complete the sentences with the correct form of the simple present tense.</p>
            <form className="exercises-form" onSubmit={handleSubmit}>
              {displayedSentences.map((sentence, index) => (
                <div key={index} className="sentence-container">
                  <span className="circle">{index + 1}</span>
                  {sentence.text.split('___').map((part, partIndex) => (
                    <React.Fragment key={partIndex}>
                      {partIndex > 0 && (
                        <input
                          type="text"
                          value={answers[index] || ''}
                          onChange={(e) => handleChange(index, e.target.value)}
                        />
                      )}
                      {part}
                    </React.Fragment>
                  ))}
                  {evaluation[index] === 'correct' ? ' ✔️' : evaluation[index] === 'incorrect' ? ' ❌' : ''}
                </div>
              ))}
               <div className="exercisectrl">
              <button type="submit" className="checkbtn">Check</button>
              <button type="submit" className="checkbtn" onClick={handleReset}>Reset</button>
              <button type="button" className="checkbtn" onClick={handlePreviousExercise}>
              Prev Set <i className="fas fa-arrow-left"></i>
              </button>
              <button type="button" className="checkbtn" onClick={handleNextExercise}>
              Next Set <i className="fas fa-arrow-right"></i>
              </button></div>
            </form>
          </div>
        </div>
        <div className="top-margin">
          <GrammarMenu />
          <Sidebar />
        </div>
      </div>
    </div>
  );
};


export default SecondConditional;

		