import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../../Components/SEO';
import Breadcrumb from '../../Components/Breadcrumb';
import GrammarMenu from '../../Components/GrammarMenu';
import Sidebar from '../../Components/Sidebar';
import Videos from '../Listening-Exercises/Videos'


const Money = () => {
    return (
    <div>
         <SEO
            title='Exam Topics - Money - English Exam Exercises'
            description='A group of English exam exercises centered around the topic of money'
            name='English Exam Exercises - Money'
            type='article' />
            <Breadcrumb />
            <div className="introtext">
        <h3 className="mt-2">Money</h3>
      <p>Here is the agenda for 12.12.24</p>
      </div>

      <div className="grammar-container">
        <div className="exercises-container div1">
        <div className='homebodytext'>
                    <div>Warmer:</div>
                    <div>Language divoint</div>
                    <div>Reading</div>
                    <div>Video
                        <Videos />
                    </div>
                    <div>Cooldown</div>
    </div>


    </div>

    </div>
            
    </div>
    )
}
 
export default Money;

