import { useNavigate, Link } from "react-router-dom";
import SEO from "../../Components/SEO";
import Breadcrumb from "../../Components/Breadcrumb";
import Sidebar from '../../Components/Sidebar';
import reading from '../../Images/reading1-1280x470.jpg';
import ReadingMenu from '../../Components/ReadingMenu';

const Readingexercises = () => {
    const navigate = useNavigate();

    const handleClick = (readingTitle) => {
        navigate('/reading-exercises/reading-comprehension', {
            state: { readingcompTitle: readingTitle }
        });
    };

    return (
        <div>
            <SEO
                title='Reading Exercises - English Exam Exercises'
                description='Reading practice exercises for every type of exam reading section'
                name='Reading English Exam Exercises'
                type='article' />


            <Breadcrumb />

  <div className='home-container'>
        
        <div className='homebodytext div1'>
            
            <img src={reading} alt="English Exam Exercises" />
            <div className="introtext">
                <h3 className="mt-2">Reading Exercises</h3></div>

            <div className="page-description">Here is a collection of vocabulary exercises to do. The exercises are divided by categories which correspond to exam levels. Each category here has 10 exercises, but there are more once you go to the page. Keep chcking back as I am always adding new exercises. 

            <div className="courses_container">
            <div className="courses_box">
    
                        <div className="blog-card">
                        <div className="description">
                          <h1>Reading Comprehension</h1>
                          <h2>Getting the basics down</h2>
                          <p>Vocabulary exercises, flashcards and quizzes to help you improve and have greater success on exams.</p>
                          <ul>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=A Year Abroad"><span className="label label-b2">B2</span> A Year Abroad</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Trekking Alone in the Arctic"><span className="label label-b2">B2</span> Trekking Alone in the Arctic</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Being a Teen"><span className="label label-b2">B2</span> Being a Teen</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=World Book Day"><span className="label label-b2">B2</span> World Book Day</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Survival Adventure Camp"><span className="label label-b2">B2</span> Survival Adventure Camp</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Hotels of the Future"><span className="label label-b2">B2</span> Hotels of the Future</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Distance Learning"><span className="label label-b2">B2</span> Distance Learning</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Night Flight"><span className="label label-b2">B2</span> Night Flight</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Red Bull Plane"><span className="label label-b2">B2</span> Red Bull Plane</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Wildlife Cameraman"><span className="label label-b2">B2</span> Wildlife Cameraman</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=UFC Monopoly Lawsuit"><span className="label label-b2">B2</span> UFC Monopoly Lawsuit</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Traditional vs. Modern Food Culture"><span className="label label-b2">B2</span> Traditional vs. Modern Food Culture</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Caffeine: The Workplace Drug of Choice"><span className="label label-b2">B2</span> Caffeine: The Workplace Drug of Choice</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=Rural Hungary"><span className="label label-b2">B2</span> Rural Hungary</Link></li>
                          <li><Link to="/reading-exercises/reading-comprehension?readingcompTitle=A Day in the Life of an Account Manager"><span className="label label-a2">A2</span> A Day in the Life of an Account Manager</Link></li>
                            </ul>
                        </div>
                      </div>
                      
                      <div className="blog-card">
                        <div className="description">
                          <h1>Paragraph Headings</h1>
                          <h2>Having an adequate vocabulary brings greater success.</h2>
                          <p>These kinds of tasks are found on TOEFL, EuroExam, and Cambridge.</p>
                          <ul>
                                    <li><Link to="/reading-exercises/paragraph-headings"><span className="label label-a2">B2</span> Paragraph Headings</Link></li>
                            </ul>
                        </div>
                      </div>
                </div>
              </div>
              </div>

              </div>

<div className="gmenu div2"> <ReadingMenu /> 
</div>

<div>

             <div><Sidebar/></div>
      
      
      </div>
  </div>



        </div>
    )
}

export default Readingexercises;



